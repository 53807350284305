import {
  hasOtherProfession,
  getSecondaryProfessions,
  getTertiaryProfessions,
} from './professionHelpers';
import { validatePassword } from '../Password/Password.helpers';
import {
  AddOrUpdateUserInput,
  GetAccountLookups_getAccountLookups_credentials,
  GetAccountLookups_getAccountLookups_ethnicities,
  GetAccountLookups_getAccountLookups_marketingSources,
  GetAccountLookups_getAccountLookups_professions,
  GetAccountLookups_getAccountLookups_workSettings,
} from './../../../models/GeneratedModels';
import { VIRGINIA_TENANT_ID, COLORADO_TENANT_ID, DEMO_TENANT_ID } from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';

const R = require('ramda');

export const getDefaultUser = (): AddOrUpdateUserInput => {
  return {

    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    otherCredential: '',
    professionId: '',
    secondLevelProfessionId: '',
    thirdLevelProfessionId: '',
    otherProfession: '',
    otherEthnicity: '',
    otherMarketingSource: '',
    otherWorkSetting: '',
    organization: '',
    organizationAddress1: '',
    organizationAddress2: '',
    city: '',
    state: '',
    countyId: '',
    zip: '',
    provideDirectCare: null,
    provideMedicaid: null,
    primaryCare: null,
    credentials: [],
    ethnicities: [],
    sexId: null,
    otherSex: '',
    marketingSourceIds: [],
    firstSeries: null,
    professionStartYear: null,
    roleDescriptionId: null,
    organizationDescriptionIds: [],
    workSettingIds: [],
    birthYear: null,
    phone: '',
    userTenantIds: [],
    isRegistrationComplete: true,
  };
};

export const getCredentialNamesFromValues = (
  credentials: GetAccountLookups_getAccountLookups_credentials[],
  selectedValues: string[]
) => {
  const credentialLabels: string[] = [];
  for (let selected of selectedValues) {
    credentialLabels.push(
      ...credentials.filter((c) => c.id === selected).map((x) => x.name)
    );
  }

  return credentialLabels.join(', ');
};

export const getEthnicityNamesFromValues = (
  ethnicities: GetAccountLookups_getAccountLookups_ethnicities[],
  selectedValues: string[]
) => {
  const ethnicityLabels: string[] = [];
  for (let selected of selectedValues) {
    ethnicityLabels.push(
      ...ethnicities.filter((c) => c.id === selected).map((x) => x.name)
    );
  }

  return ethnicityLabels.join(', ');
};

export const validateUserForm = (
  user: AddOrUpdateUserInput,
  credentials: GetAccountLookups_getAccountLookups_credentials[],
  ethnicities: GetAccountLookups_getAccountLookups_ethnicities[],
  professions: GetAccountLookups_getAccountLookups_professions[],
  mode: 'create' | 'update'
): string[] => {
  const validationErrors: string[] = [];
  const tenantId = getTenantIdForDomain();

  if (mode === 'create') {
    if (!hasStringValue(user.password))
      validationErrors.push('Password is required');
    if (!hasStringValue(user.confirmPassword))
      validationErrors.push('Confirm password is required');

    const passwordValidation = validatePassword(
      user.password ?? '',
      user.confirmPassword ?? ''
    );
    if (!passwordValidation.isValid)
      validationErrors.push(passwordValidation.message ?? '');
  }

  if (!hasStringValue(user.firstName))
    validationErrors.push('firstName is required');
  if (!hasStringValue(user.lastName))
    validationErrors.push('lastName is required');
  if (!hasStringValue(user.email)) validationErrors.push('email is required');

  if (tenantId !== VIRGINIA_TENANT_ID) {
    if (!hasStringValue(user.phone)) validationErrors.push('phone is required');
    if (!hasBooleanValue(user.provideMedicaid))
      validationErrors.push('provideMedicaid is required');
    if (!hasBooleanValue(user.primaryCare))
      validationErrors.push('primaryCare is required');
  }
  if (!hasStringValue(user.professionId)) validationErrors.push('professionId is required');
  if (!hasStringValue(user.organization)) validationErrors.push('organization is required');
  if (!hasStringValue(user.organizationAddress1)) validationErrors.push('organizationAddress1 is required');
  if (!hasStringValue(user.city)) validationErrors.push('city is required');
  if (!hasStringValue(user.state)) validationErrors.push('state is required');
  // debugger
  if (user.state !== 'XX' && !hasStringValue(user.countyId)) validationErrors.push('countyId is required');

  if (tenantId !== VIRGINIA_TENANT_ID) {
    if (!hasStringValue(user.sexId)) validationErrors.push('identity is required');
  }

  if (tenantId === COLORADO_TENANT_ID || tenantId === DEMO_TENANT_ID) {
    if (!hasStringValue(user.roleDescriptionId)) validationErrors.push('roleDescription is required');
    if (!hasBooleanValue(user.firstSeries)) validationErrors.push('firstSeries is required');
    if (!hasArrayValue(user.marketingSourceIds)) validationErrors.push('marketingSourceIds is required');
    if (!hasNumberValue(user.professionStartYear)) validationErrors.push('professionStartYear is required');
    if (!hasNumberValue(user.birthYear)) validationErrors.push('birthYear is required');
  }
  
  if (!hasStringValue(user.zip)) validationErrors.push('zip is required');
  if (!hasBooleanValue(user.provideDirectCare))
    validationErrors.push('provideDirectCare is required');
  if (!hasArrayValue(user.credentials))
    validationErrors.push('credentials is required');
  if (!hasArrayValue(user.ethnicities))
    validationErrors.push('ethnicities is required');

  if (hasStringValue(user.professionId)) {
    const professionSecondLevels = getSecondaryProfessions(
      user.professionId ?? '',
      professions
    );
    if (
      professionSecondLevels.length > 0 &&
      !hasStringValue(user.secondLevelProfessionId)
    ) {
      validationErrors.push('Secondary profession is required');
    }
  }

  if (hasStringValue(user.secondLevelProfessionId)) {
    const professionTertiaryLevels = getTertiaryProfessions(
      user.professionId ?? '',
      user.secondLevelProfessionId ?? '',
      professions
    );
    if (
      professionTertiaryLevels.length > 0 &&
      !hasStringValue(user.thirdLevelProfessionId)
    ) {
      validationErrors.push('Tertiary profession is required');
    }
  }

  if (hasOtherCredentialSelected(user.credentials ?? [], credentials)) {
    if (!hasStringValue(user.otherCredential)) {
      validationErrors.push('Other credential is required');
    }
  }

  if (hasOtherEthnicitySelected(user.ethnicities ?? [], ethnicities)) {
    if (!hasStringValue(user.otherEthnicity)) {
      validationErrors.push('Other ethnicity is required');
    }
  }

  if (
    hasOtherProfession(
      user.professionId ?? '',
      user.secondLevelProfessionId ?? '',
      user.thirdLevelProfessionId ?? '',
      professions
    )
  ) {
    if (!hasStringValue(user.otherProfession)) {
      validationErrors.push('Other profession is required');
    }
  }

  return validationErrors;
};

export const hasOtherCredentialSelected = (
  selectedCredentialIds: string | string[],
  credentials: GetAccountLookups_getAccountLookups_credentials[]
): boolean => {
  if (!selectedCredentialIds || selectedCredentialIds.length === 0)
    return false;

  let showOther = false;

  for (let credentialId of selectedCredentialIds) {
    const credentialsIndex = R.findIndex(R.propEq('id', credentialId))(
      credentials
    );
    // if (!credentialsIndex || !credentials[credentialsIndex]) return false;

    if (credentials[credentialsIndex]?.isOther) {
      showOther = true;
      break;
    }
  }

  return showOther;
};

export const hasOtherMarketingSourceSelected = (
  selectedMarketingSourceIds: string | string[],
  marketingSourceIds: GetAccountLookups_getAccountLookups_marketingSources[]
): boolean => {
  if (!selectedMarketingSourceIds || selectedMarketingSourceIds.length === 0)
    return false;

  let showOther = false;

  for (let marketingSourceId of selectedMarketingSourceIds) {
    const marketingSourcesIndex = R.findIndex(
      R.propEq('id', marketingSourceId)
    )(marketingSourceIds);
    if (!marketingSourcesIndex || !marketingSourceIds[marketingSourcesIndex])
      return false;

    if (marketingSourceIds[marketingSourcesIndex].sortOrder === 4) {
      showOther = true;
      break;
    }
  }

  return showOther;
};

export const hasOtherWorkSettingSelected = (
  selectedWorkSettingIds: string | string[],
  workSettingIds: GetAccountLookups_getAccountLookups_workSettings[]
): boolean => {
  if (!selectedWorkSettingIds || selectedWorkSettingIds.length === 0)
    return false;

  let showOther = false;

  for (let workSettingId of selectedWorkSettingIds) {
    const workSettingsIndex = R.findIndex(R.propEq('id', workSettingId))(
      workSettingIds
    );
    // if (!workSettingsIndex || !workSettingIds[workSettingsIndex]) return false;

    if (workSettingIds[workSettingsIndex]?.isOther) {
      showOther = true;
      break;
    }
  }

  return showOther;
};

export const hasOtherEthnicitySelected = (
  selectedEthnicityIds: string | string[],
  ethnicities: GetAccountLookups_getAccountLookups_ethnicities[]
): boolean => {
  if (!selectedEthnicityIds || selectedEthnicityIds.length === 0) return false;

  let showOther = false;

  for (let ethnicityId of selectedEthnicityIds) {
    const ethnicitiesIndex = R.findIndex(R.propEq('id', ethnicityId))(
      ethnicities
    );
    // if (!ethnicitiesIndex || !ethnicities[ethnicitiesIndex]) return false;

    if (ethnicities[ethnicitiesIndex]?.isOther) {
      showOther = true;
      break;
    }
  }

  return showOther;
};

function hasStringValue(valueToCheck: string | null | undefined) {
  const trimmedValue = valueToCheck?.trim();
  if (!trimmedValue || trimmedValue.length === 0) return false;

  return true;
}

function hasNumberValue(valueToCheck: number | null | undefined) {
  if (valueToCheck === null || valueToCheck === undefined) return false;

  return true;
}

function hasBooleanValue(valueToCheck: boolean | null | undefined) {
  if (valueToCheck === null || valueToCheck === undefined) return false;

  return true;
}

function hasArrayValue(valueToCheck: string[] | null | undefined) {
  if (!valueToCheck || valueToCheck.length === 0) return false;

  return true;
}
