import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getTenantIdForDomain, graphQL_APIURI } from './helpers/tenantHelpers';
import { USER_TOKEN } from 'utils/constants';

import './index.css';
import App from './App';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Handle the specific error: Invalid token
    console.error(graphQLErrors);
    
    // Handle GraphQL errors globally
    graphQLErrors.forEach((error: any) => {
      if (
        error.extensions?.code === 'UNAUTHENTICATED' ||
        (error.extensions?.code === 'INTERNAL_SERVER_ERROR' && 
         (error.message?.includes('Invalid token') || 
          error.message?.includes('Context creation failed'))) ||
        error.extensions?.code === 'Not authenticated' ||
        error.message?.includes('Unauthorized') ||
        error.message?.includes('Unauthorizied') ||
        error.message?.includes('Authentication token expired')
      ) {
        console.error('Authentication error detected - redirecting to logout');
        window.location.href = '/logout';
      } else {
        console.error('GraphQL error:', error);
      }
    });
  }

  if (networkError) {
    // Handle network errors globally
    console.error('Network error:', networkError);
  }
});


const httpLink = createHttpLink({
  uri: graphQL_APIURI,
  credentials: 'include',
  fetchOptions: {
    mode: 'cors',
  },
});

const tenantId = getTenantIdForDomain();

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      tenant: tenantId,
      credentials: 'include',
    },
  };
});

const cacheControlLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, cacheControlLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);
