import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import { Grid, TextField, Card, CardContent, Button, Typography, MenuItem, InputLabel, FormControl, FormHelperText, List, ListItem, ListItemText, Box } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddStaffAccount from '../../components/admin/Account/AddStaffAccount';
import PageHeader from '../../components/global/PageHeader';
import AccountList from '../../components/admin/Account/AccountList';
import ProgressIndicator from '../../components/global/ProgressIndicator';

import { SearchUsersByField, SearchUsersByFieldVariables, SearchAllUserRoles } from '../../models/GeneratedModels';
import { searchUserByField_Gql } from '../../gql/user/searchUserByField';
import { searchAllUserRoles_Gql } from '../../gql/user/searchAllUserRoles';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';

import { useAuth } from 'hooks/useAuth';

interface SearchProperties {
  search: string;
  field: string;
}

const charLimits = {
  email: 4,
  default: 2
};

const roleOptions = [
  'Administrator',
  'ECHOStaff',
  'Development',
  'Implementation',
  'Evaluation',
  'Partner',
];

const Accounts = () => {
  const [searchProperties, setSearchProperties] = useState<SearchProperties>({ search: '', field: 'lastName' });
  const [searchWasRun, setSearchWasRun] = useState(false);
  const [isFieldChangeLoading, setIsFieldChangeLoading] = useState(false);
  const [isCountLinkClickedLoading, setIsCountLinkClickedLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [roleCounts, setRoleCounts] = useState<Record<string, number>>({
    Total: 0,
    Administrator: 0,
    ECHOStaff: 0,
    Development: 0,
    Implementation: 0,
    Evaluation: 0,
    Partner: 0,
    Learner: 0
  });
  const [allUserRoleCounts, setAllUserRoleCounts] = useState<Record<string, number>>({
    Total: 0,
    Administrator: 0,
    ECHOStaff: 0,
    Development: 0,
    Implementation: 0,
    Evaluation: 0,
    Partner: 0,
    Learner: 0
  });

  const [searchAccounts, { loading, data }] = useLazyQuery<SearchUsersByField, SearchUsersByFieldVariables>(
    searchUserByField_Gql,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchAllRoles, { loading: loadingAllUserRoles, data: dataAllUserRoles }] = useLazyQuery<SearchAllUserRoles>(searchAllUserRoles_Gql, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const counts = {
        Total: 0,
        Administrator: 0,
        ECHOStaff: 0,
        Development: 0,
        Implementation: 0,
        Evaluation: 0,
        Partner: 0,
        Learner: 0
      };
      
      type RoleKeys = keyof typeof counts;
      
      data.allUserRolesCount.forEach((role) => {
        const roleName = role.role_name as RoleKeys;
        if (roleName in counts) {
          counts[roleName] = role.count;
          counts.Total += role.count;
        }
      });
      
      setRoleCounts(counts);
      setAllUserRoleCounts(counts);
    }
  });

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff; // auth.user?.isPartner;
  };

  const validateInput = (): boolean => {
    // Skip validation for role-based searches
    if (searchProperties.field === 'role') {
      setError(null);
      return true;
    }

    const limit = searchProperties.field === 'email' ? charLimits.email : charLimits.default;
    if (searchProperties.search.length < limit) {
      setError(`Search term must be at least ${limit} characters.`);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !error) {
      e.preventDefault();
      onSearch();
    }
  };  

  const onSearch = () => {
    if (!isCountLinkClickedLoading && !validateInput()) {
      return;
    }
    setSearchWasRun(true);
    setIsFieldChangeLoading(false);
    searchAccounts({
      variables: {
        search: searchProperties.search,
        field: searchProperties.field,
      },
      onCompleted: (data) => {
        // Reset role counts if search results are empty
        if (!data.searchUsersByField || data.searchUsersByField.length === 0) {
          setRoleCounts(allUserRoleCounts);
        }
      }
    });
  };

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProperties({
      ...searchProperties,
      search: e.target.value,
    });

    setError(null);
  };

  const onFieldChange = (event: SelectChangeEvent<string>) => {
    const newField = event.target.value;
    // Only show loading when switching to/from role field
    if (searchProperties.field === 'role' || newField === 'role') {
      setIsFieldChangeLoading(true);
      setTimeout(() => setIsFieldChangeLoading(false), 300);
    }
    
    setSearchProperties({
      ...searchProperties,
      field: newField,
      // Clear the search value when switching fields
      search: ''
    });
    
    setError(null);
  };

  const onReset = () => {
    setSearchWasRun(false);
    setSearchProperties({ search: '', field: 'lastName' });
    setError(null);
    setRoleCounts(allUserRoleCounts);
  };

  const users = data?.searchUsersByField ?? [];

  // Add this new function to handle role selection
  const handleRoleClick = (role: string) => {
    if (!searchWasRun) {
      setIsCountLinkClickedLoading(true);
      
      // First trigger the field change
      const event = {
        target: { value: 'role' }
      } as SelectChangeEvent<string>;
      onFieldChange(event);

      // Then update search properties and perform search after a delay
      setTimeout(() => {
        setSearchProperties(prev => ({
          ...prev,
          field: 'role',
          search: role
        }));

        // Perform search with the new properties
        setSearchWasRun(true);
        setIsFieldChangeLoading(false);
        searchAccounts({
          variables: {
            search: role,
            field: 'role',
          },
          onCompleted: (data) => {
            // Reset role counts if search results are empty
            if (!data.searchUsersByField || data.searchUsersByField.length === 0) {
              setRoleCounts(allUserRoleCounts);
            }
            
            setIsCountLinkClickedLoading(false);
          }
        });
      }, 500);
    } else {
      // Update search properties even when search was already run
      setSearchProperties(prev => ({
        ...prev,
        search: role
      }));
      
      // Perform the search with new role
      searchAccounts({
        variables: {
          search: role,
          field: 'role',
        },
        onCompleted: (data) => {
          if (!data.searchUsersByField || data.searchUsersByField.length === 0) {
            setRoleCounts(allUserRoleCounts);
          }
        }
      });
    }
  };

  // Add this effect after other useEffects
  useEffect(() => {
    fetchAllRoles();
  }, [fetchAllRoles]);

  const renderSearchInput = () => {
    if (isFieldChangeLoading) {
      return <ProgressIndicator isOpen={true} title="Updating Search Field..." />;
    }

    if (searchProperties.field === 'role') {
      return (
        <FormControl fullWidth error={!!error}>
          <InputLabel size="small">Search Role</InputLabel>
          <Select
            size="small"
            value={searchProperties.search}
            label="Search Role"
            onChange={(e) => {
              setSearchProperties({
                ...searchProperties,
                search: e.target.value
              });
              setError(null);
            }}
          >
            {roleOptions.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error || ' '}</FormHelperText>
        </FormControl>
      );
    }

    return (
      <TextField
        sx={{ width: '100%' }}
        size='small'
        label="Search"
        value={searchProperties.search}
        onChange={onFormElementChange}
        error={!!error}
        helperText={error || ' '}
        onKeyDown={onKeyDown}
      />
    );
  };

  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading || loadingAllUserRoles} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 6 }}>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <PageHeader title="Accounts" />
        </Box>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <AddStaffAccount />
            
            <Card sx={{ textAlign: 'left', mt: 'auto' }}>
              <CardContent>
                <Typography sx={{ marginBottom: '20px' }} gutterBottom variant="h5" component="div">
                  User Search
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Search Field</InputLabel>
                      <Select
                        size='small'
                        value={searchProperties.field}
                        label="Search Field"
                        onChange={onFieldChange}
                      >
                        <MenuItem value="lastName">Last Name</MenuItem>
                        <MenuItem value="firstName">First Name</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="organization">Organization</MenuItem>
                        <MenuItem value="role">Role</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    {renderSearchInput()}
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={onSearch} sx={{ width: '40%' }} color="primary" variant="contained" disabled={!!error}>
                      Search
                    </Button>
                    <Button onClick={onReset} sx={{ width: '40%', ml: 2 }} color="primary" variant="outlined">
                      Reset
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="caption" sx={{ mt: 0.5 }}>Select a field and enter a search term</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Column */}
          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ textAlign: 'left', mt: 'auto' }}>
              <CardContent sx={{ py: '12px !important' }}>
                <Typography 
                  sx={{ marginBottom: '8px' }}
                  gutterBottom 
                  variant="h5" 
                  component="div"
                >
                  User-Roles Summary
                </Typography>
                <List sx={{ py: 0 }}>
                  {['Total', 'Administrator', 'ECHOStaff', 'Partner', 'Learner',].map((role) => (
                    <ListItem 
                      key={role} 
                      sx={{ 
                        py: 0,
                        cursor: role !== 'Total' && role !== 'Learner' ? 'pointer' : 'default',
                        '&:hover': {
                          backgroundColor: role !== 'Total' && role !== 'Learner' ? 'action.hover' : 'inherit'
                        }
                      }}
                      onClick={() => role !== 'Total' && role !== 'Learner' && handleRoleClick(role)}
                    >
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body2">{role}</Typography>
                            <Typography variant="body2" component="span">
                              {roleCounts[role] || 0}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {!loading && 
              <AccountList 
              users={users} 
              searchWasRun={searchWasRun}
            />
            } 
          </Grid>
        </Grid>
      </Container>
    </>
    )}
    </>
  ));
};

export default Accounts;
