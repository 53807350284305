// import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { Tooltip, Alert, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';

import { SearchUsersByName_searchUsersByName } from '../../../models/GeneratedModels';
import ViewAccountLink from './ViewAccountLink';
import ManageUserPermissions from '../../account/Permissions/ManageUserPermissions';
import EditStaffAccount from '../Account/EditStaffAccount';
import ChangePassword from '../Account/ChangePassword';
import ProgressIndicator from 'components/global/ProgressIndicator';

// Remove the AccountListRef interface
interface AccountListProps {
  users: SearchUsersByName_searchUsersByName[];
  searchWasRun: boolean;
}

const AccountList = ({ users, searchWasRun }: AccountListProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const hasEmptySearch = searchWasRun && users.length === 0;

  const getCountForRole = (role: string): number => {
    // Return total users count when role is 'Total'
    if (role === 'Total') {
      return users.length;
    }
    
    // For Learner role, check for both 'Learner' and 'Registrant'
    if (role === 'Learner') {
      return users.filter(user => 
        user.userRolesForDisplay && 
        (user.userRolesForDisplay.indexOf('Registrant') >= 0 || 
         user.userRolesForDisplay.indexOf('Learner') >= 0)
      ).length;
    }
    
    // Otherwise filter by specific role
    return users.filter(user => 
      user.userRolesForDisplay && user.userRolesForDisplay.indexOf(role) >= 0
    ).length;
  };

  if (hasEmptySearch) {
    return <Alert severity="info">No results found</Alert>;
  }

  // const onSaveClick = () => {
  //   console.log('save');
  // };

  const userIsAdmin = (user: SearchUsersByName_searchUsersByName) => {
    if (!user || !user.userRolesForDisplay) return false;

    return user.userRolesForDisplay.indexOf('ECHOStaff') >= 0;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLoading(true);
    setSelectedTab(newValue);
    // Simulate a small delay to show loading state
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const tabs = [
    'Total',
    'Administrator',
    'ECHOStaff',
    'Development',
    'Implementation',
    'Evaluation',
    'Partner',
    'Learner'
  ];

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      {searchWasRun && (
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="left">Credentials</TableCell>
                  <TableCell align="left">Organization</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <ViewAccountLink userId={user.id} name={`${user.lastName}, ${user.firstName}`} email={user.email} />
                      <Typography variant="caption">
                        {user.userRolesForDisplay?.replace('Registrant', 'Learner')}
                      </Typography>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell align="left">{user.userCredentialsForDisplay}</TableCell>
                    <TableCell align="left">{user.organization}</TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`Edit ${user.firstName}`}>
                        {userIsAdmin(user) ? (
                          <EditStaffAccount user={user} />
                        ) : (
                          <RouterLink to={`/admin/account/edit/${user.id}`}>
                            <EditIcon color="primary" sx={{ fontSize: '16px' }} />
                          </RouterLink>
                        )}
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <ChangePassword userId={user.id} firstName={user.firstName ?? ''} lastName={user.lastName ?? ''} />
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`Manage permissions for ${user.firstName}`}>
                        <ManageUserPermissions
                          userId={user.id}
                          firstName={user.firstName ?? ''}
                          lastName={user.lastName ?? ''}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`View Cohorts as ${user.firstName}`}>
                        <RouterLink to="/NotImplemented" target="_blank">
                          <ArrowForwardIosIcon color="primary" sx={{ ml: 1, fontSize: '16px' }} />
                        </RouterLink>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      )}
    </>
  );
};

export default AccountList;
