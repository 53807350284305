import { gql } from '@apollo/client';

export const searchAllUserRoles_Name = 'SearchAllUserRoles';
export const searchAllUserRoles_Gql = gql`
  query SearchAllUserRoles {
    allUserRolesCount {
      role_id, 
      role_name
      count
    }
  }
`;
