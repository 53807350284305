export const countryCodes = [
  { code: '+1', country: 'US', mask: '(999) 999-9999' },
  { code: '+7', country: 'RU', mask: '(999) 999-99-99' },
  { code: '+20', country: 'EG', mask: '99 9999 9999' },
  { code: '+27', country: 'ZA', mask: '99 999 9999' },
  { code: '+30', country: 'GR', mask: '999 999 9999' },
  { code: '+31', country: 'NL', mask: '99 99999999' },
  { code: '+32', country: 'BE', mask: '999 99 99 99' },
  { code: '+33', country: 'FR', mask: '9 99 99 99 99' },
  { code: '+34', country: 'ES', mask: '999 99 99 99' },
  { code: '+36', country: 'HU', mask: '99 999 9999' },
  { code: '+39', country: 'IT', mask: '999 999 9999' },
  { code: '+40', country: 'RO', mask: '999 999 999' },
  { code: '+41', country: 'CH', mask: '99 999 99 99' },
  { code: '+43', country: 'AT', mask: '999 999999' },
  { code: '+44', country: 'GB', mask: '9999 999999' },
  { code: '+45', country: 'DK', mask: '99 99 99 99' },
  { code: '+46', country: 'SE', mask: '99 999 99 99' },
  { code: '+47', country: 'NO', mask: '999 99 999' },
  { code: '+48', country: 'PL', mask: '999 999 999' },
  { code: '+49', country: 'DE', mask: '999 99999999' },
  { code: '+51', country: 'PE', mask: '999 999 999' },
  { code: '+52', country: 'MX', mask: '(999) 999 9999' },
  { code: '+53', country: 'CU', mask: '9 999 9999' },
  { code: '+54', country: 'AR', mask: '(99) 9999 9999' },
  { code: '+55', country: 'BR', mask: '(99) 99999 9999' },
  { code: '+56', country: 'CL', mask: '9 9999 9999' },
  { code: '+57', country: 'CO', mask: '999 999 9999' },
  { code: '+58', country: 'VE', mask: '9999 999 9999' },
  { code: '+60', country: 'MY', mask: '99 999 9999' },
  { code: '+61', country: 'AU', mask: '9999 999 999' },
  { code: '+62', country: 'ID', mask: '999 9999 9999' },
  { code: '+63', country: 'PH', mask: '999 999 9999' },
  { code: '+64', country: 'NZ', mask: '99 999 9999' },
  { code: '+65', country: 'SG', mask: '9999 9999' },
  { code: '+66', country: 'TH', mask: '9 9999 9999' },
  { code: '+81', country: 'JP', mask: '99 9999 9999' },
  { code: '+82', country: 'KR', mask: '99 9999 9999' },
  { code: '+84', country: 'VN', mask: '99 999 99 99' },
  { code: '+86', country: 'CN', mask: '999 9999 9999' },
  { code: '+90', country: 'TR', mask: '(999) 999 9999' },
  { code: '+91', country: 'IN', mask: '99999 99999' },
  { code: '+92', country: 'PK', mask: '999 999 9999' },
  { code: '+93', country: 'AF', mask: '99 999 9999' },
  { code: '+94', country: 'LK', mask: '99 999 9999' },
  { code: '+95', country: 'MM', mask: '9 999 9999' },
  { code: '+98', country: 'IR', mask: '999 999 9999' },
  { code: '+212', country: 'MA', mask: '999 999999' },
  { code: '+213', country: 'DZ', mask: '999 99 99 99' },
  { code: '+216', country: 'TN', mask: '99 999 999' },
  { code: '+221', country: 'SN', mask: '99 999 99 99' },
  { code: '+225', country: 'CI', mask: '99 99 99 99 99' },
  { code: '+234', country: 'NG', mask: '999 999 9999' },
  { code: '+237', country: 'CM', mask: '9 99 99 99 99' },
  { code: '+243', country: 'CD', mask: '999 999 999' },
  { code: '+250', country: 'RW', mask: '999 999 999' },
  { code: '+251', country: 'ET', mask: '99 999 9999' },
  { code: '+254', country: 'KE', mask: '999 999999' },
  { code: '+255', country: 'TZ', mask: '999 999 999' },
  { code: '+256', country: 'UG', mask: '999 999999' },
  { code: '+260', country: 'ZM', mask: '99 999 9999' },
  { code: '+263', country: 'ZW', mask: '9 999999' },
  { code: '+351', country: 'PT', mask: '999 999 999' },
  { code: '+353', country: 'IE', mask: '99 999 9999' },
  { code: '+358', country: 'FI', mask: '99 999 9999' },
  { code: '+359', country: 'BG', mask: '999 999 999' },
  { code: '+370', country: 'LT', mask: '(9-999) 9999' },
  { code: '+371', country: 'LV', mask: '99 999 999' },
  { code: '+372', country: 'EE', mask: '9999 9999' },
  { code: '+374', country: 'AM', mask: '99 999999' },
  { code: '+375', country: 'BY', mask: '(99) 999-99-99' },
  { code: '+380', country: 'UA', mask: '(99) 999 99 99' },
  { code: '+385', country: 'HR', mask: '99 999 9999' },
  { code: '+386', country: 'SI', mask: '99 999 999' },
  { code: '+420', country: 'CZ', mask: '999 999 999' },
  { code: '+421', country: 'SK', mask: '999 999 999' },
  { code: '+502', country: 'GT', mask: '9999 9999' },
  { code: '+503', country: 'SV', mask: '9999 9999' },
  { code: '+504', country: 'HN', mask: '9999 9999' },
  { code: '+505', country: 'NI', mask: '9999 9999' },
  { code: '+506', country: 'CR', mask: '9999 9999' },
  { code: '+507', country: 'PA', mask: '9999 9999' },
  { code: '+509', country: 'HT', mask: '99 99 9999' },
  { code: '+591', country: 'BO', mask: '9 999 9999' },
  { code: '+593', country: 'EC', mask: '99 999 9999' },
  { code: '+595', country: 'PY', mask: '999 999999' },
  { code: '+598', country: 'UY', mask: '9 999 99 99' },
  { code: '+675', country: 'PG', mask: '999 99999' },
  { code: '+679', country: 'FJ', mask: '999 9999' },
  { code: '+852', country: 'HK', mask: '9999 9999' },
  { code: '+853', country: 'MO', mask: '9999 9999' },
  { code: '+856', country: 'LA', mask: '99 99 999 999' },
  { code: '+880', country: 'BD', mask: '9999 999999' },
  { code: '+886', country: 'TW', mask: '9999 999999' },
  { code: '+960', country: 'MV', mask: '999 9999' },
  { code: '+962', country: 'JO', mask: '9 9999 9999' },
  { code: '+963', country: 'SY', mask: '999 999 999' },
  { code: '+964', country: 'IQ', mask: '999 999 9999' },
  { code: '+965', country: 'KW', mask: '9999 9999' },
  { code: '+966', country: 'SA', mask: '99 999 9999' },
  { code: '+967', country: 'YE', mask: '999 999 999' },
  { code: '+968', country: 'OM', mask: '9999 9999' },
  { code: '+970', country: 'PS', mask: '999 999 999' },
  { code: '+971', country: 'AE', mask: '99 999 9999' },
  { code: '+972', country: 'IL', mask: '99 999 9999' },
  { code: '+973', country: 'BH', mask: '9999 9999' },
  { code: '+974', country: 'QA', mask: '9999 9999' },
  { code: '+976', country: 'MN', mask: '99 99 9999' },
  { code: '+977', country: 'NP', mask: '99 9999 9999' },
  { code: '+992', country: 'TJ', mask: '999 99 9999' },
  { code: '+993', country: 'TM', mask: '9 99 999999' },
  { code: '+994', country: 'AZ', mask: '99 999 99 99' },
  { code: '+995', country: 'GE', mask: '999 99 99 99' },
  { code: '+996', country: 'KG', mask: '(999) 999 999' },
  { code: '+998', country: 'UZ', mask: '99 999 99 99' },
];
